export const accessControl: {
  [feature in Feature]: { [planType in PlanType]: boolean };
} = {
  "ai-sidebar": {
    complete: true,
    core: false,
    "core-with-ai": true,
    enterprise: false,
    "ai-only": true,
  },
  "ai-settings": {
    complete: true,
    core: false,
    "core-with-ai": true,
    enterprise: false,
    "ai-only": true,
  },
  "full-settings": {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": false,
  },
  "patient-charts": {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": false,
  },
  "notes-and-forms": {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": true,
  },
  calendar: {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": false,
  },
  messaging: {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": true,
  },
  "session-audio-upload": {
    complete: true,
    core: false,
    "core-with-ai": true,
    enterprise: true,
    "ai-only": false,
  },
  "group-sessions": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "business-analytics": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "orchid-storage": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "calendar-sync": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "can-contact-professionals": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "load-previous-note": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "note-file-upload": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: true,
    "ai-only": false,
  },
  "ai-patient-chart": {
    complete: false,
    core: false,
    "core-with-ai": false,
    enterprise: false,
    "ai-only": true,
  },
  "ai-home": {
    complete: false,
    core: false,
    "core-with-ai": false,
    enterprise: false,
    "ai-only": true,
  },
  "ai-session-review": {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: false,
    "ai-only": true,
  },

  // TODO: confirm these are gated correctly
  "audio-recorder": {
    complete: true,
    core: false,
    "core-with-ai": true,
    enterprise: false,
    "ai-only": true,
  },

  waitlist: {
    complete: true,
    core: true,
    "core-with-ai": true,
    enterprise: false,
    "ai-only": false,
  },

  "medication-history": {
    complete: true,
    core: false,
    "core-with-ai": false,
    enterprise: false,
    "ai-only": false,
  },
};
